<template>
  <div>
    <div class="row mt-2 mx-2">
      <div class="col-lg-4 col-md-4 col-sm-12" v-for="item in system_cards" :key="item.title" >
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 2 : 1" :class="{ up: hover }" outlined link color="rgba(255,255,255,0)" :to="item.link">
            <v-list-item two-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{ item.title }}
                </div>
                <v-list-item-title v-if="item.title == 'ACTIVE PUBLIC SURVEYS'" class="text-h5 mb-1">
                  {{ systemSummary.activePublicSurveys }}
                </v-list-item-title>
                <v-list-item-title v-else-if="item.title == 'INACTIVE PUBLIC SURVEYS'">
                  {{ systemSummary.inactivePublicSurveys }}
                </v-list-item-title>
                <v-list-item-title v-else-if="item.title == 'ACTIVE PRIVATE SURVEYS'" class="text-h5 mb-1">
                  {{ systemSummary.activePrivateSurveys }}
                </v-list-item-title>
                <v-list-item-title v-else-if="item.title == 'INACTIVE PRIVATE SURVEYS'" class="text-h5 mb-1">
                  {{ systemSummary.intactivePrivateSurveys }}
                </v-list-item-title>
                <v-list-item-title
                  v-else-if="item.title == 'ACTIVE INSTITUTIONS'"
                  class="text-h5 mb-1"
                  >{{
                    systemSummary.activeInstitutions
                  }}</v-list-item-title
                >
                <v-list-item-title
                  v-else-if="item.title == 'INACTIVE INSTITUTIONS'"
                  class="text-h5 mb-1"
                  >{{ systemSummary.inactiveInstitutions }}</v-list-item-title
                >
                <v-list-item-title
                  v-else-if="item.title == 'QUIZES'"
                  class="text-h5 mb-1"
                  >{{
                    systemSummary.quizes
                  }}</v-list-item-title
                >
              </v-list-item-content>

              <v-list-item-avatar tile size="70" color="white">
                <v-icon
                  style="font-size: 72px"
                  dark
                  large
                  :color="item.color"
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-hover>
      </div>
    </div>
    <div class="row mt-2 mx-2">
      <div class="col-12">
        <v-divider />
      </div>
    </div>
    <div class="row mt-2 mx-2">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="items-center" style="border: 1px solid gray; background-color: azure;">
          <apexchart
            type="bar"
            width="100%"
            :options="options"
            :series="series"
            ref="myChart"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "Dashboard",

  data: () => ({
    system_cards: [
      {
        title: "ACTIVE PUBLIC SURVEYS",
        link: "/lite/survey-list",
        icon: "mdi-ballot",
        color: "#db9b32",
      },
      {
        title: "INACTIVE PUBLIC SURVEYS",
        link: "/lite/survey-list",
        icon: "mdi-ballot",
        color: "#1a568a",
      },
      {
        title: "ACTIVE PRIVATE SURVEYS",
        link: "/lite/survey-list",
        icon: "mdi-ballot",
        color: "#11662e",
      },
      {
        title: "INACTIVE PRIVATE SURVEYS",
        link: "/lite/survey-list",
        icon: "mdi-ballot",
        color: "#11662e",
      },
      {
        title: "ACTIVE INSTITUTIONS",
        link: "/lite/institution-managements",
        icon: "mdi-domain",
        color: "#11662e",
      },
      {
        title: "INACTIVE INSTITUTIONS",
        link: "/lite/institution-management",
        icon: "mdi-domain",
        color: "#11662e",
      },
      {
        title: "QUIZES",
        link: "/lite/quizes",
        icon: "mdi-ballot",
        color: "#11662e",
      },
    ],
    options: {
      title: {
        text: "public vs private surveys for this month",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#448ee4",
        },
      },
      chart: {
        id: "vuechart-example",
      },
      xaxis: {
        categories: ['public surveys' , 'private surveys'],
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],

    chartOptions: {
      title: {
        text: "Surveys Vs. Quizes",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#448ee4",
        },
      },
      chart: {
        width: 380,
        type: "pie",
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          }
        }
      },
      labels: ["Surveys", "Quiz"]

    },
  }),

  methods: {},

  computed: {
    ...mapGetters({
      permissions: "getUserPermissions",
      systemSummary: "getSysAdminDashboardSummary",
    }),
  },
  async mounted() {
    await this.$store.dispatch("fetchSysAdminDashboardSummary");
    await this.$store.dispatch('fetchsysAdminMonthlyVisualizations');

    this.series[0].data = [this.$store.getters.getSysAdminMonthlyVisualizations.publicSurveyCounts, 
                            this.$store.getters.getSysAdminMonthlyVisualizations.privateSurveyCounts]
    this.$refs.myChart.refresh();
  },
};
</script>